<template>
  <div id="newsCenter">
    <loading v-if="loading" :loading="loading"></loading>
    <div v-else>
      <el-row type="flex" justify="center">
      <el-col :xs="22" :sm="18" :md="18" :lg="18" :xl="18">
        <div class="newContainer">
          <table>
              <div v-for="(n,index) in newsMsg" :key="index">
                <tr @click="goToNews(n.id)">
                  <td>
                      <img v-lazy="n.thumbimg" alt="">
                  </td>
                  <td valign="top">
                      <div class="textBox">
                        <h3 class="text_ov_el">{{n.title}}</h3>
                        <p class="text_l3">{{n.intro}}</p>
                        <p>{{n.createdAt}}</p>
                      </div>
                  </td>
                </tr>
                <hr>
              </div>
          </table>
        </div>
      </el-col>
    </el-row>
    </div>
  </div>
</template>

<script>
import Loading from "components/Loading";

import img1 from "assets/images/苏州台案例照片-1.jpg";
import { newsApi } from "@/request/api.js";

export default {
  name: "NewsCenter",
  components: {
    Loading,
  },
  data() {
    return {
      loading: true,
      newsMsg: [],
    };
  },
  computed: {
    lang() {
      return this.$store.state.lang;
    },
    eq() {
      return this.$store.state.eq;
    },
  },
  watch: {
    immediate: true,
    lang(newLang, oldLang) {
      newsApi(newLang).then((res) => {
        this.newsMsg = res.data.rows;
        this.newsMsg.forEach((value, index, arr) => {
          this.newsMsg[index].createdAt = value.createdAt.slice(0, 10);
        });
      });
    },
  },
  methods: {
    goToNews(msg) {
      let routeData = this.$router.resolve({
        name: "News",
        query: {
          pageIndex: msg,
          eq: this.eq,
        },
      });
      window.open(routeData.href, "_blank");
    },
  },
  mounted() {
    newsApi(this.$store.state.lang)
      .then((res) => {
        this.newsMsg = res.data.rows;
        this.newsMsg.forEach((value, index, arr) => {
          this.newsMsg[index].createdAt = value.createdAt.slice(0, 10);
        });
        this.loading = false;
      })
      .catch((error) => {
        console.log("error: ", error);
        this.loading = false;
      });
  },
};
</script>


<style lang="scss" scoped>
@import "assets/scss/mixin.scss";
@import "assets/scss/global.scss";

.textBox {
  h3 {
    font-weight: bold;
  }
}

.newContainer {
  @include flex("center", "center");
  flex-wrap: wrap;
  margin-top: 150px;
  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 15px;
  }
  td {
    height: 160px;
    img {
      width: 287.2px;
      object-fit: cover;
    }
  }
  table tr td:nth-child(1) {
    width: 300px;
    img {
      border-radius: 5px;
    }
  }
  table tr td:nth-child(2) {
    width: 800px;
    @media screen and (min-width: 1900px) {
      width: 1053px;
    }

    padding-left: 15px;
    .textBox {
      height: 156px;
    }
    p:nth-child(2) {
      height: 90px;
    }
    p:nth-child(3) {
      text-align: right;
    }
  }
  hr {
    margin: 15px 0;
  }
}
@media screen and(max-width:1200px) {
  #newsCenter {
    min-height: 700px;
  }
  .newContainer {
    margin-top: 80px;
    table {
      border-collapse: separate;
      border-spacing: 0px;
    }
    td {
      height: 80px;
      img {
        object-fit: cover;
      }
    }
    table tr td:nth-child(1) {
      width: 150px;
      img {
        border-radius: 5px;
        width: 150px;
        height: 76px;
      }
    }
    table tr td:nth-child(2) {
      .textBox {
        height: 50px;
        h3 {
          font-size: 16px;
          width: 170px;
          margin: 0px;
          padding: 0;
        }
        p {
          margin: 0px;
          padding: 0;
        }
        p:nth-child(2) {
          height: 40px !important;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
        p:nth-child(3) {
          bottom: 0;
        }
      }
    }
  }
}
</style>
